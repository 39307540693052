import React from 'react'
import Layout from "../components/layout"
const ResumePage = () => {
    return (
        <Layout>
            <h1>Resume</h1>
        </Layout>
        
    )
}

export default ResumePage
